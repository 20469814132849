<template>
    <st-page :title="titleComputed" :backLink="backLink" layout="tab" :subtitle="applicationDate" pageCustomClass="application-view">
        <loader v-if="showLoader" />
        <template #additional-title v-if="applicationStatus">
            <div v-can:read="'applicationsStaff'" v-if="processError" :class="`label label-lg label-inline label-light-danger ml-6`">{{ processError }}</div>
            <st-button
                v-can:read="'applicationsStaff'"
                v-if="processError"
                variant="link"
                class="st-btn-text-danger"
                :spinner="isLoadingStartProcess"
                :callback="startProcess"
            >
                <i class="fa fa-wrench"></i>
                {{ $t("APPLICATION.VIEW.START_PROCESS") }}
            </st-button>

            <!-- applicant user -->
            <div v-can:read="'applications'" :class="`label label-lg label-inline label-light-${applicationStatusApplicant.type} ml-6`">{{ applicationStatusApplicant.text }}</div>

            <div v-can:read="'applicationsStaff'" :class="`label label-lg label-inline label-light-${applicationStatus.type} ml-6`">{{ applicationStatus.text }}</div>
            <div v-can:read="'applicationsStaff'" :class="`label label-lg label-inline label-light-${applicationStatus.type} ml-6`" v-if="showSigningStatus">{{ signingStatus }}</div>
            <div v-can:read="'applicationsStaff'" :class="`label label-lg label-inline label-light-${applicationStatus.type} ml-6`" v-if="hasRelatedApplications && waitForRelatedApplications">{{ $t("APPLICATION.FORM.PENDING_RELATED_APPLICATIONS") }}</div>
            <div v-can:read="'applicationsStaff'" :class="`label label-lg label-inline label-warning ml-6`" v-if="hasPendingInternalApplications">{{ $t("APPLICATION.FORM.PENDING_INTERNAL_APPLICATIONS") }}</div>
            <st-button
                v-can:read="'internalDocuments'"
                variant="link"
                class="pl-12 st-btn-text-primary"
                :callback="goToInternalDocuments"
            >
                <i class="fa fa-file"></i>
                {{ $t("APPLICATION.VIEW.VIEW_INTERNAL_DOCUMENTS") }}
            </st-button>
        </template>
        <template #toolbar>
            <application-view-toolbar v-if="recordLoaded" v-can:read="'applicationsStaffOwn'"/>

            <application-view-toolbar-applicant v-if="recordLoaded" v-can:read="'applications'"/>

            <div v-if="needsReview" class="row" v-can:edit="'application'">
                <st-button
                    variant="light"
                    class="st-btn-text-warning"
                    :spinner="isLoadingSaveDraft"
                    :callback="sendClarification">
                    <i class="fa fa-question"></i>
                    {{ $t("APPLICATION.FORM.SEND_REVIEW") }}
                </st-button>
            </div>
        </template>

        <b-tabs class="st-tabs" justified lazy v-model="activeTab" v-if="recordLoaded">
            <b-tab
                v-for="tab in tabs"
                :key="tab.index"
                :disabled="tab.disabled"
                :title-item-class="navItemClass(tab)"
                @click.stop="goToPage(tab)"
            >
                <template #title>
                    <div class="d-flex justify-content-center w-100">
                        <i :class="['fas', tab.icon]"></i>
                        <span class="text-uppercase ml-4">{{
                            $t(tab.title)
                        }}</span>
                    </div>
                </template>
                <component
                    :is="tab.componentType"
                    :applicationId="id"
                    :ref="tab.ref"
                    v-if="activeTabName === tab.name"
                    @componentEvent="handleEvent"
                    @app-summary-mounted="handleAppSummaryMounted"
                    @form-mounted="handleFormMounted"
                    @map-mounted="handleMapMounted"
                    :noBack="tab.noBack"
                    :requireAttachments="requireAttachments"
                    :record="record"
                >
                </component>
            </b-tab>
        </b-tabs>

        <application-send-review-modal
            v-if="record"
            :applicationId="record.id"
            ref="application-send-review-modal"
            @documentCompleted="handleDocumentCompleted"
            @documentError="handleDocumentError"
        />

        <confirm-sign-modal
            v-if="record"
            :applicationId="record.id"
            :review="true"
            ref="confirm-sign-document-modal"
            @signValidated="signValidated"
        />

    </st-page>
</template>

<script>
import { ApplicationsPermissions } from "@/modules/applications/applications-permissions";
import { mapActions, mapGetters } from "vuex";
import Message from "@/shared/message/message";
import moment from 'moment';
import { ApplicationModel } from "@/modules/applications/models/application-model";
import { RE_ENROLL_ERRORS } from "@/modules/applications/utils/constants";

const { statuses } = ApplicationModel;

export default {
    name: "ApplicationsView",
    components: {
        ApplicationViewToolbar: () => import("@/modules/applications/components/view-application/ApplicationViewToolbar"),
        ApplicationViewToolbarApplicant: () => import("@/modules/applications/components/view-application/ApplicationViewToolbarApplicant"),
        ApplicationDetailsTab: () => import("@/modules/applications/components/view-application/ApplicationDetailsTab"),
        ApplicationDocumentsTab: () => import("@/modules/applications/components/view-application/documents/ApplicationDocumentsTab"),
        ApplicationActivityTab: () => import("@/modules/applications/components/view-application/ApplicationActivityTab"),
        ApplicationPaymentsTab: () => import("@/modules/applications/components/view-application/ApplicationPaymentsTab"),
        ApplicationRegisterDetailsFormTab: () => import("@/modules/applications/components/view-application/ApplicationRegisterDetailsFormTab"),
        ApplicationDocumentsEdit: () => import('@/modules/applications/components/view-application/documents/ApplicationDocumentsEdit.vue'),
        ApplicationSendReviewModal: () => import('@/modules/applications/components/view-application/ApplicationSendReviewModal.vue'),
        ApplicationRegisterForm: () => import('@/modules/applications/components/ApplicationRegisterForm.vue'),
        ConfirmSignModal: () => import("@/modules/applications/components/signature/ConfirmSignModal"),
    },
    props: {
        id: String,
    },
    data() {
        return {
            activeTab: 0,
            goToSectionId: this.$route.query?.goToSection ?? '',
            activeTabName: 'details',
            recordLoaded: false,
            formLoaded: false,
            mapLoaded: false,
            processingActions: [],
            assignedUser: null,
            loadingSign: false,
            requireAttachments: false,
        };
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            loading: "shared/loading",
            currentUser: "auth/currentUser",
            uploadAccessToken: "applications/form/uploadAccessToken",
            appConfig: "shared/appConfig",
            accountDetails: "auth/accountDetails",
        }),
        hasPermissionToOrganisationsApplication() {
            return new ApplicationsPermissions(this.currentUser).readStaff;
        },
        hasPermissionToStaffOwnApplications() {
            return new ApplicationsPermissions(this.currentUser).readOwnStaff;
        },
        hasPermissionToReadStaff() {
            return this.hasPermissionToOrganisationsApplication || this.hasPermissionToStaffOwnApplications;
        },
        hasPermissionToViewPayments() {
            return new ApplicationsPermissions(this.currentUser).viewPayments;
        },
        applicationTypeName() {
            return this.record?.application_type_name;
        },
        titleComputed() {
            if (!this.id) return this.$t("APPLICATIONS.VIEW.TITLE");
            if (!this.record) return '';
            if (this.record?.status === 'draft') {
                return this.applicationTypeName;
            }

            const uid = this.record?.identification_number;

            return `${uid} - ${this.applicationTypeName}`;
        },
        backLink() {
            return this.id ? { name: "applications" } : "";
        },
        isLoading() {
            return this.loading["applications/find"];
        },
        isLoadingSaveDraft() {
            return this.loading["applications/update"];
        },
        isLoadingStartProcess() {
            return this.loading["applications/startProcess"];
        },
        hasIdentificationNumber() {
            return this.record?.identification_number;
        },
        isDigitalized() {
            return this.record?.status === 'registered' && !!this.record?.digitalized;
        },
        tabs() {
            let tabs = [];

            tabs.push({
                index: 0,
                name: "register_details",
                title: this.$t("APPLICATION.TABS.REGISTRY_DETAILS"),
                disabled: false,
                componentType: "ApplicationRegisterForm",
                ref: 'applicationRegisterForm',
            });
            if (this.record?.status === 'registered') {
                tabs.push({
                    index: 1,
                    name: "application_details",
                    title: this.$t("APPLICATION.TABS.APPLICATION_FORM"),
                    componentType: "ApplicationRegisterDetailsFormTab",
                    disabled: !this.isDigitalized,
                    ref: 'applicationRegisterDetails',
                });
            }
            if (this.record?.status !== 'registered') {
                tabs.push({
                    index: 2,
                    name: "details",
                    title: this.$t("APPLICATION.TABS.APPLICATION"),
                    componentType: "ApplicationDetailsTab",
                    disabled: false,
                    ref: 'applicationDetails',
                });
                if (this.hasPermissionToReadStaff) {
                    tabs.push(
                        {
                            index: 3,
                            name: "form",
                            title: this.applicationTypeName,
                            disabled: !this.hasIdentificationNumber,
                            componentType: "ApplicationDocumentsEdit",
                            noBack: true,
                        },
                    );
                }
                tabs.push({
                    index: 4,
                    name: "documents",
                    title: this.$t("APPLICATION.TABS.DOCUMENTS"),
                    componentType: "ApplicationDocumentsTab",
                    disabled: !this.hasIdentificationNumber,
                });
                if (this.hasPermissionToViewPayments) {
                    tabs.push(
                        {
                            index: 5,
                            name: "payments",
                            title: this.$t("APPLICATION.TABS.PAYMENTS"),
                            componentType: "ApplicationPaymentsTab",
                            disabled: !this.hasIdentificationNumber,
                        }
                    );
                }
            }
            tabs.push(
                {
                    index: 6,
                    name: "activity",
                    title: this.$t("APPLICATION.TABS.ACTIVITY"),
                    componentType: "ApplicationActivityTab",
                    disabled: !this.hasIdentificationNumber,
                }
            )
            return tabs;
        },
        isLoadingSave() {
            return this.loading["applications/update"];
        },
        showLoader() {
            if (this.activeTabName === 'register_details') {
                return this.isLoading || !this.recordLoaded;
            }
            if (this.activeTabName === 'details' || this.activeTabName === 'form') {
                // TODO - add mapLoaded when map is implemented
                // return this.isLoading || !this.recordLoaded || !this.formLoaded || !this.mapLoaded || this.loadingSign;
                return this.isLoading || !this.recordLoaded || !this.formLoaded || this.loadingSign;
            }
            return this.isLoading;
        },
        applicationDate() {
            if (!this.record?.application_date) return '';
            return moment(new Date(this.record.application_date)).format(this.appConfig.DATE_FORMAT);
        },
        applicationStatus() {
            if (!this.record) return null;
            return statuses.find((el) => el.value === this.record.internal_status);
        },
        applicationStatusApplicant() {
            if (!this.record) return null;
            return statuses.find((el) => el.value === this.record.status);
        },
        processError() {
            if (!this.record) return null;
            if (this.record.process_status === 'error') {
                return this.$t('APPLICATION.VIEW.PROCESS_ERROR');
            }
            return null;
        },
        needsReview() {
            return this.activeTab === 0 && this.record?.status === 'review';
        },
        hasRelatedApplications() {
            if (!this.record) return false;

            return this.record?.related_application_ids?.length > 0;
        },
        waitForRelatedApplications() {
            if (this.processingActions.length) {
                const relatedApplicationsAction = this.processingActions.filter((el) => el.action_type === 'WAIT_FOR_RELATED_APPLICATIONS');
                return relatedApplicationsAction.length > 0;
            }

            return false;
        },
        hasPendingInternalApplications() {
            if (!this.record) return false;

            return this.record?.has_pending_internal_applications;
        },
        signingStatus() {
            if (this.record.to_sign_by_assigned_staff) {
                return this.assignedUser.functions.join(',')
            } else {
                if (this.processingActions.length) {
                    const signingAction = this.processingActions.find((el) => el.action_type === 'SIGN_DOCUMENT');
                    if (signingAction) {
                        return signingAction.function_type;
                    }
                }
            }

            return '-';
        },
        showSigningStatus() {
            const signingAction = this.processingActions?.find((el) => el.action_type === 'SIGN_DOCUMENT');
            return signingAction && this.assignedUser;
        }
    },
    methods: {
        ...mapActions({
            doFind: "applications/form/find",
            doClear: "applications/form/clear",
            doUpdate: "applications/form/update",
            doUpload: "applications/form/upload",
            resetUserActions: "applications/form/doResetUserActions",
            sendComment: "applications/activity/sendComment",
            doFindUserActions: "applications/form/findUserActions",
            initApplicationState: "applications/form/initFormState",
            findUser: "applications/form/doFindAssignedStaffAccount",
            sendReview: "applications/form/sendReview",
            signDoc: "applications/form/signDocumentCitizen",
            doEnrollment: "applications/form/enrollPerson",
            doStartProcess: "applications/form/startProcess",
        }),
        navItemClass(tab) {
            if (tab?.disabled) return "";
            if (this.id) return "nav-item--success";
            return "nav-item--warning";
        },
        async goToPage(tab) {
            if (tab?.name) {
                this.activeTabName = tab.name;
                await this.$router.push({
                    params: {
                        tab: tab?.name
                    }
                });
            }
        },
        async generateData(asDraft) {
            const mainRef = this.$refs.applicationDetails[0].$refs;
            const genFormData = await mainRef.generateForm.getData(asDraft);

            const formData = {
                ...genFormData,
            };

            return formData;
        },
        handleEvent(callback) {
            if (this[callback]) {
                this[callback]();
            }
        },
        openClarificationModal() {
            this.$refs['application-send-review-modal'].show();
        },
        async sendClarification() {
            const data = await this.generateData(false);

            const identificationFiles = [];
            let attachedFiles = [];
            if (data.form?.attachments?.length) {
                const formData = new FormData();
                let needUpload = false;
                for( let i = 0; i < data.form?.attachments?.length; i++) {
                    const file = data.form.attachments[i];
                    if (file instanceof File) {
                        formData.append('files', file);
                        needUpload = true;
                    } else {
                        identificationFiles.push(file);
                    }
                }
                if (needUpload) {
                    const uploadedFiles = await this.doUpload({
                        files: formData,
                        uploadAccessToken: this.uploadAccessToken,
                    });
                    attachedFiles = uploadedFiles.map((el) => ({
                        file_id: el.id,
                        filename: el.initialFilename,
                        created_date: el.created_date,
                        file_type: 'input',
                        created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`
                    }));
                } else {
                    Message.error('APPLICATION.VIEW.UPLOAD_FILES');
                    return;
                }

                const payload = {
                    id: this.record.id,
                    values: {
                        identification_files: [
                            ...identificationFiles,
                            ...attachedFiles,
                        ],
                    },
                };

                try {
                    await this.doUpdate(payload);

                    this.openClarificationModal();
                } catch {
                    Message.error('APPLICATION.VIEW.REVIEW_NOT_SENT');
                }

            } else {
                Message.error('APPLICATION.VIEW.UPLOAD_FILES');
                this.requireAttachments = true;
                const el = document.querySelector("#application-attachments");
                if (el) {
                    const elementPosition = el.offsetTop;
                    setTimeout(() => {
                        window.scrollTo({
                            top: elementPosition,
                            behavior: 'smooth',
                        });
                    }, 1000);
                }
                return;
            }

        },
        async handleDocumentCompleted() {
            await this.doSign();
        },
        handleDocumentError() {
            const title = this.$t('APPLICATION.NOTIFIERS.GENERATION_ERROR');
            const text = this.$t('APPLICATION.NOTIFIERS.GENERATION_ERROR_TEXT');
            this.$alert({
                type: 'error',
                title,
                text: text,
                isTextHtml: true,
                showConfirmButton: false,
            });
        },
        async doSign() {
            this.loadingSign = true;

            let enrollmentRequest = {
                user_id: this.currentUser.user_id,
                email: this.currentUser.email,
                first_name: this.currentUser.first_name,
                last_name: this.currentUser.last_name,
                id_card_number: this.currentUser.id_card_number,
                id_card_serial: this.currentUser.id_card_serial,
                id_card_expiration_date: this.currentUser.id_card_expiration_date,
                id_card_issuer: this.currentUser.id_card_issuer,
                cnp: this.currentUser.personal_numeric_code,
                phone_number: this.currentUser.phone_number,
                county: this.currentUser.county,
                type: 0,
            };

            const showModalInnerMethod = () => {
                this.loadingSign = false;
                this.$nextTick(() => {
                    this.$refs["confirm-sign-document-modal"].show();
                });
            };

            if (this.currentUser.enrolled) {
                try {
                    await this.signDoc({ id: this.record.id, review: true });
                    showModalInnerMethod();
                } catch (err) {
                    if (RE_ENROLL_ERRORS.includes(err)) {
                        await this.doEnrollment({
                            ...enrollmentRequest,
                            force: true,
                        });
                        await this.signDoc({ id: this.record.id, review: true });
                        showModalInnerMethod();
                        return;
                    }

                    Errors.handle(err);
                }

                return;
            }

            await this.doEnrollment(enrollmentRequest);
            await this.signDoc({ id: this.record.id, review: true });

            showModalInnerMethod();
        },
        async signValidated() {
            await this.sendMessage();
            await this.doFind({
                id: this.id,
                loadStaff: this.hasPermissionToReadStaff,
            });
            Message.success("APPLICATION.NOTIFIERS.REVIEW_SENT");
        },
        async sendMessage() {
            const params = {
                id: this.record.id,
                loadStaff: this.hasPermissionToReadStaff,
                payload: {
                    comment: this.$t('APPLICATION.NOTIFIERS.REVIEW_SENT'),
                    visibility: 'public'
                }
            };

            await this.sendComment(params);
        },
        goToSection(sectionID) {
            const el = this.$refs.applicationDetails[0].$refs[sectionID].$el;
            if (el) {
                const elementPosition = el.offsetTop;
                window.scrollTo({
                    top: elementPosition,
                    behavior: 'smooth',
                });
            }
        },
        handleAppSummaryMounted() {
            this.$nextTick(() => {
                // scroll to section Id section after the summary section is mounted
                if (this.goToSectionId) {
                    setTimeout(() => {
                        this.goToSection(this.goToSectionId);
                        // clear query
                        this.$router.replace({
                            ...this.$router.currentRoute,
                            query: null
                         });
                    }, 1000);
                }
            });
        },
        handleFormMounted() {
            this.formLoaded = true;
        },
        handleMapMounted() {
            this.mapLoaded = true;
        },
        async getUserActions(){
            const paramsProcessing = {
                query: `application_id eq ${this.record.id} and status eq PROCESSING`,
                sort: '-created_date'
            };

            this.processingActions = await this.doFindUserActions(paramsProcessing);
        },
        goToInternalDocuments() {
            this.$router.push({ name: 'internal-documents', query: { parent_application_identification_number: this.record?.identification_number } });
        },
        async startProcess() {
            await this.doStartProcess(this.record.id);
        },
    },
    watch: {
        isDigitalized(value) {
            if (value && this.record.status === 'registered') {
                // Navigate to `application_details` tab
                this.$nextTick(() => {
                    this.activeTabName = this.tabs[1].name;
                    this.activeTab = this.tabs[1].index;
                });
            }
        }
    },
    async created() {
        if (this.id && !this.record) {
            try {
                await this.doFind({
                    id: this.id,
                    loadStaff: this.hasPermissionToReadStaff,
                });
                this.recordLoaded = true;
            } catch {
                this.recordLoaded = false;

                return await this.$router.push({
                    name: 'applications'
                });
            }

        }
        this.recordLoaded = true;

        if (!this.id) {
            await this.doClear();
        }

        if (this.$route.params?.tab) {
            const activeIndex = this.tabs.findIndex(tab => tab.name === this.$route.params?.tab);
            this.activeTab = activeIndex;
            this.activeTabName = this.$route.params?.tab;
        } else {
            this.activeTabName = this.tabs[0].name;
        }

        this.resetUserActions();

        if (this.hasPermissionToReadStaff) {
            await this.getUserActions();
            this.assignedUser = this.accountDetails;

            if (this.record?.assigned_staff_user_id && this.record?.assigned_staff_user_id !== this.currentUser.user_id) {
                this.assignedUser = await this.findUser(this.record.assigned_staff_user_id);
            }
        }
    },
    beforeDestroy() {
        this.initApplicationState();
    }
};
</script>
<style scoped>
@import '~quill/dist/quill.snow.css';
</style>
